<template lang="pug">
v-container.create_team
  v-row.py-3.py-md-7(justify="space-between")
    v-col.text-center.text-md-left(cols="12", md="3", lg="3")
      h2.text_f_primary 創建球隊
    v-col.btn_container.d-flex(cols="12", md="5", lg="4")
      .btn.btn_gray_hollow.flex-grow-1.mx-3(@click="toMyteam") 取消
      .btn.btn_water_blue_hollow.flex-grow-1.mx-3(
        v-if="step == 2",
        @click="preStep"
      ) 上一步
      .btn.btn_water_blue.flex-grow-1.mx-3(
        v-if="step == 2",
        @click="createTeam"
      ) 確認隊伍
      .btn.btn_water_blue_hollow.flex-grow-1.mx-3(
        v-if="step == 1",
        @click="nextStep"
      ) 下一步
  div(v-if="step == 1")
    v-divider.d-none.d-md-block
    v-row.bottom_actions.mt-0.mt-md-7.d-md-flex(justify="end")
      v-col.pl-0.pl-md-3(cols="12", lg="6")
        h3.font-weight-regular 選擇本土球員
      v-col.pl-0.pl-md-3.pr-0.pr-md-1(cols="6", lg="3")
        select(v-model="position", @change="filterChange")
          option(value="ALL") 所有位置
          option(value="G") G
          option(value="F") F
          option(value="C") C
      v-col.pr-0.pr-md-3(cols="6", lg="3")
        input(placeholder="球員姓名", v-model="nameText")
    v-row.player_lists_wrapper.flex-column-reverse.flex-md-row.mt-0.mt-md-7
      v-col(cols="12", md="6")
        .selected_player_list.d-flex.flex-column
          .selected_player_list_header.d-flex.align-center.justify-space-between
            svg-icon(
              :class="{ arrow_disable: selectedPlayerPage == 1 }",
              @click="onSelectedPlayerLeftArrowClick()",
              iconClass="arrow_left_round_rect",
              className="arrow_left_round_rect"
            )
            .d-flex.align-center
              p 已選球員列表
              p.font-weight-light.ml-2 {{ selectedPlayerPage }}/{{ selectedPlayerList.length > 0 ? Math.ceil(selectedPlayerList.length / 10) : 1 }}
            svg-icon(
              :class="{ arrow_disable: selectedPlayerPage == (selectedPlayerList.length > 0 ? Math.ceil(selectedPlayerList.length / 10) : 1) }",
              @click="onSelectedPlayerRightArrowClick()",
              iconClass="arrow_right_round_rect",
              className="arrow_right_round_rect"
            )
          .selected_player_list_body.flex-grow-1
            v-row.table_row.mt-0(style="padding: 0")
              v-col.table.f_table.table_col
                table
                  thead
                    tr.bottom_head
                      th.bdl 名字
                      th 球隊
                      th 位置
                      th
                        span 價值
                      th 
                  tbody
                    tr(
                      @click="onSelectedPlayItemClick(player)",
                      v-for="(player, index) in currentSelectedPlayerList",
                      :key="index"
                    )
                      td.bdl {{ player.name }}
                      td {{ player.team_name }}
                      td {{ player.position }}
                      td(style="color: #c5af30") {{ player.current_value }}
                      td
                        svg-icon.mr-1(iconClass="minus")
          .selected_player_list_footer.d-flex.justify-space-between.align-center
            .use_status
              .player_amount.mr-2
                p 本土球員
                .values
                  span.used {{ selectedLocalPlayerList.length }}
                  span.total.ml-1 / {{ maxLocalPlayer }}
            .use_status
              .used_value.mr-2
                p 使用價值
                .values
                  span.used {{ selectedPoints }}
                  span.total.ml-1 / {{ totalPoints }}
              .left_value.mr-2
                p 剩餘價值
                span {{ totalPoints - selectedPoints }}
      v-col(cols="12", md="6")
        .player_list
          .player_list_header.d-flex.align-center.justify-space-between
            svg-icon(
              :class="{ arrow_disable: playerPage == 1 }",
              @click="onPlayerLeftArrowClick()",
              iconClass="arrow_left_round_rect",
              className="arrow_left_round_rect"
            )
            .d-flex.align-center
              p 球員列表
              p.font-weight-light.ml-2 {{ playerPage }}/{{ tempPlayerList.length > 0 ? Math.ceil(tempPlayerList.length / 10) : 1 }}
            svg-icon(
              :class="{ arrow_disable: playerPage == (tempPlayerList.length > 0 ? Math.ceil(tempPlayerList.length / 10) : 1) }",
              @click="onPlayerRightArrowClick()",
              iconClass="arrow_right_round_rect",
              className="arrow_right_round_rect"
            )
          .player_list_body
            v-row.table_row.mt-0(style="padding: 0")
              v-col.table.f_table.table_col
                table
                  thead
                    tr.bottom_head
                      th.bdl 名字
                      th 球隊
                      th 位置
                      th(
                        style="cursor: pointer",
                        @click="sortClick('current_value', 'INFO')",
                        :class="{ sort_up: sortValue.type == 'current_value' && sortValue.order == 'ASC', sort_down: sortValue.type == 'current_value' && sortValue.order == 'DESC' }"
                      )
                        span 價值
                        font-awesome-icon.ml-2(
                          :icon="getTableSort('current_value')"
                        )
                      th 
                  tbody
                    tr(
                      @click="onPlayItemClick(player)",
                      v-for="(player, index) in currentPlayerList",
                      :key="index"
                    )
                      td.bdl {{ player.name }}
                      td {{ player.team_name }}
                      td {{ player.position }}
                      td(style="color: #c5af30") {{ player.current_value }}
                      td
                        svg-icon.mr-1(iconClass="plus")

  div(v-if="step == 2")
    v-divider.d-none.d-md-block
    v-row.bottom_actions.mt-0.mt-md-7.d-md-flex(justify="end")
      v-col.pl-0.pl-md-3(cols="12", lg="6")
        h3.font-weight-regular 選擇洋將
      v-col.pl-0.pl-md-3.pr-0.pr-md-1(cols="6", lg="3")
        select(v-model="position", @change="filterChange")
          option(value="ALL") 所有位置
          option(value="G") G
          option(value="F") F
          option(value="C") C
      v-col.pr-0.pr-md-3(cols="6", lg="3")
        input(placeholder="球員姓名", v-model="nameText")
    v-row.mt-0.mt-md-7.player_lists_wrapper.flex-column-reverse.flex-md-row
      v-col(cols="12", md="6")
        .selected_player_list.d-flex.flex-column
          .selected_player_list_header.d-flex.align-center.justify-space-between
            svg-icon(
              :class="{ arrow_disable: selectedPlayerPage == 1 }",
              @click="onSelectedPlayerLeftArrowClick()",
              iconClass="arrow_left_round_rect",
              className="arrow_left_round_rect"
            )
            .d-flex.align-center
              p 已選球員列表
              p.font-weight-light.ml-2 {{ selectedPlayerPage }}/{{ selectedPlayerList.length > 0 ? Math.ceil(selectedPlayerList.length / 10) : 1 }}
            svg-icon(
              :class="{ arrow_disable: selectedPlayerPage == (selectedPlayerList.length > 0 ? Math.ceil(selectedPlayerList.length / 10) : 1) }",
              @click="onSelectedPlayerRightArrowClick()",
              iconClass="arrow_right_round_rect",
              className="arrow_right_round_rect"
            )
          .selected_player_list_body.flex-grow-1
            v-row.table_row.mt-0(style="padding: 0")
              v-col.table.f_table.table_col
                table
                  thead
                    tr.bottom_head
                      th.bdl 名字
                      th 球隊
                      th 位置
                      th
                        span 價值
                      th 
                  tbody
                    tr(
                      @click="onSelectedPlayItemClick(player)",
                      v-for="(player, index) in currentSelectedPlayerList",
                      :key="index"
                    )
                      td.bdl {{ player.name }}
                      td {{ player.team_name }}
                      td {{ player.position }}
                      td(style="color: #c5af30") {{ player.current_value }}
                      td
                        svg-icon.mr-1(iconClass="minus")
          .selected_player_list_footer.d-flex.justify-space-between.align-center
            //- p.value_total 價值總額
            //-   span {{ totalPoints }}
            .use_status
              .player_amount.mr-2
                p 洋將 (固定3位)
                .values
                  span.used {{ selectedForeignPlayerList.length }}
                  span.total.ml-1 / {{ maxForeignPlayer }}
      v-col(cols="12", md="6")
        .player_list
          .player_list_header.d-flex.align-center.justify-space-between
            svg-icon(
              :class="{ arrow_disable: playerPage == 1 }",
              @click="onPlayerLeftArrowClick()",
              iconClass="arrow_left_round_rect",
              className="arrow_left_round_rect"
            )
            .d-flex.align-center
              p 球員列表
              p.font-weight-light.ml-2 {{ playerPage }}/{{ tempPlayerList.length > 0 ? Math.ceil(tempPlayerList.length / 10) : 1 }}
            svg-icon(
              :class="{ arrow_disable: playerPage == (tempPlayerList.length > 0 ? Math.ceil(tempPlayerList.length / 10) : 1) }",
              @click="onPlayerRightArrowClick()",
              iconClass="arrow_right_round_rect",
              className="arrow_right_round_rect"
            )
          .player_list_body
            v-row.table_row.mt-0(style="padding: 0")
              v-col.table.f_table.table_col
                table
                  thead
                    tr.bottom_head
                      th.bdl 名字
                      th 球隊
                      th 位置
                      th(
                        style="cursor: pointer",
                        @click="sortClick('current_value', 'INFO')",
                        :class="{ sort_up: sortValue.type == 'current_value' && sortValue.order == 'ASC', sort_down: sortValue.type == 'current_value' && sortValue.order == 'DESC' }"
                      )
                        span 價值
                        font-awesome-icon.ml-2(
                          :icon="getTableSort('current_value')"
                        )
                      th 
                  tbody
                    tr(
                      @click="onPlayItemClick(player)",
                      v-for="(player, index) in currentPlayerList",
                      :key="index"
                    )
                      td.bdl {{ player.name }}
                      td {{ player.team_name }}
                      td {{ player.position }}
                      td(style="color: #c5af30") {{ player.current_value }}
                      td
                        svg-icon.mr-1(iconClass="plus")
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-error(
    :title="dialogErrorData.title",
    @closeErrorDialog="closeErrorDialog",
    :dialogError="dialogErrorShow"
  )
    template(#content)
      p {{ dialogErrorData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
</template>

<script>
import {
  getSeasonData,
  getSeasonDraftData,
  createTeam,
  checkMyTeam,
} from "@/api/fantasy";
import { validStringEmpty } from "@/utils/validate";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogLoading from "@/components/dialog/DialogLoading";

export default {
  name: "CreateTeam",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    DialogSuccess,
    DialogError,
    DialogLoading,
  },
  data() {
    return {
      seasonData: {},

      step: 1,

      isTeamExist: false,

      position: "ALL",
      nameText: "",

      playerPage: 1,
      playerList: [],
      tempPlayerList: [],
      currentPlayerList: [],

      totalPoints: 0,
      maxLocalPlayer: 0,
      maxForeignPlayer: 0,
      selectedPoints: 0,

      selectedPlayerPage: 1,
      selectedLocalPlayerList: [],
      selectedForeignPlayerList: [],
      selectedPlayerList: [],
      currentSelectedPlayerList: [],

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogErrorShow: false,
      dialogErrorData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },

      sortValue: {
        type: "",
        order: "",
      },

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "GC Fantasy 創建球隊 | Game Changer",
        description: "GC Fantasy 創建球隊",
        keywords: "Game changer,籃球,會員,GC Fantasy",
        url: "https://www.gamechanger.tw/fantasy/CreateTeam",
        image: "",
        smoType: "website",
      },
    };
  },
  watch: {
    nameText() {
      this.filterChange();
    },
    step() {
      this.playerPage = 1;
      this.selectedPlayerPage = 1;
      this.selectedPlayerList = [];
      this.currentPlayerList = [];
      this.tempPlayerList = [];
      if (this.step == 1) {
        this.selectedPlayerList.push(...this.selectedLocalPlayerList);
        this.getSelectedPlayersPage(this.selectedPlayerPage);
        this.sortValue.type = "";
        this.sortValue.order = "";
        this.position = "ALL";
        this.nameText = "";
        this.getSeasonDraftData("LOCAL", null, null);
      } else if (this.step == 2) {
        this.selectedPlayerList.push(...this.selectedForeignPlayerList);
        this.getSelectedPlayersPage(this.selectedPlayerPage);
        this.sortValue.type = "";
        this.sortValue.order = "";
        this.getSeasonDraftData("FOREIGN", null, null);
      }
    },
  },
  created() {
    this.getSeasonData();
  },
  methods: {
    getSeasonData() {
      let temp = {
        state: "IN_SEASON",
      };
      getSeasonData(temp).then((response) => {
        if (response.data.length > 0) {
          this.seasonData = response.data[0];

          this.totalPoints = this.seasonData.config.max_player_value;
          this.maxLocalPlayer = this.seasonData.config.team_max_local_player;
          this.maxForeignPlayer =
            this.seasonData.config.team_max_foreign_player;

          this.checkMyTeam();

          this.getSeasonDraftData("LOCAL", null, null);
        }
      });
    },
    checkMyTeam() {
      if (this.$store.state.user.accessToken) {
        let temp = {
          season_id: this.seasonData.id,
        };
        checkMyTeam(temp).then((response) => {
          if (response.data.has_team) {
            this.toMyteam();
          }
        });
      }
    },
    getSeasonDraftData(country, position, keyword) {
      let temp = {
        season_id: this.seasonData.id,
        country: country,
        position: position,
        keyword: keyword,
      };
      getSeasonDraftData(temp).then((response) => {
        this.playerList = response.data;
        this.tempPlayerList = Object.assign([], this.playerList);
        this.playerPage = 1;
        this.getPlayersPage(this.playerPage);
      });
    },
    createTeam() {
      if (this.selectedForeignPlayerList.length < this.maxForeignPlayer) {
        this.showErrorDialog(
          "錯誤",
          "請至少選" + this.maxForeignPlayer + "位洋將"
        );
        return;
      }

      this.showLoadingDialog("球隊建立中...");

      let roster = "";
      for (let index in this.selectedLocalPlayerList) {
        let temp = this.selectedLocalPlayerList[index];
        roster = roster + temp.id + ",";
      }

      for (let index in this.selectedForeignPlayerList) {
        let temp = this.selectedForeignPlayerList[index];
        roster = roster + temp.id + ",";
      }

      let temp = {
        season_id: this.seasonData.id,
        roster: roster,
      };

      createTeam(temp)
        .then(() => {
          this.closeLoadingDialog();
          this.showSuccessDialog("成功", "球隊創立成功!");
          setTimeout(() => {
            this.closeSuccessDialog();
            this.toMyteam();
          }, 2000);
        })
        .catch((error) => {
          this.closeLoadingDialog();
          this.showErrorDialog("創立失敗", error.response.data.error_msg);
        });
    },
    getPlayersPage(page) {
      this.playerPage = page;

      this.removePlayerList();

      this.currentPlayerList = [];
      let number = 10 * this.playerPage;
      this.currentPlayerList = this.tempPlayerList.slice(number - 10, number);
    },
    removePlayerList() {
      let tempList = Object.assign([], this.playerList);
      this.tempPlayerList = [];

      let selectedID = [];
      for (let i in this.selectedPlayerList) {
        let tempSelected = this.selectedPlayerList[i];
        selectedID.push(tempSelected.id);
      }
      for (let index in tempList) {
        let player = tempList[index];
        if (!selectedID.includes(player.id)) {
          this.tempPlayerList.push(player);
        }
      }

      if (this.sortValue.type != "" && this.sortValue.order != "") {
        this.tempPlayerList = this.sortData(
          this.tempPlayerList,
          this.sortValue.type,
          "INFO",
          this.sortValue.order
        );
      }
    },
    onPlayerLeftArrowClick() {
      if (this.playerPage - 1 > 0) {
        this.getPlayersPage(this.playerPage - 1);
      }
    },
    onPlayerRightArrowClick() {
      if (this.playerPage + 1 <= Math.ceil(this.tempPlayerList.length / 10)) {
        this.getPlayersPage(this.playerPage + 1);
      }
    },
    onPlayItemClick(player) {
      this.selectedPlayerList = [];
      if (this.step == 2) {
        if (this.selectedForeignPlayerList.length >= this.maxForeignPlayer) {
          this.showErrorDialog("加入失敗", "洋將已經超過上限");
          this.selectedPlayerList.push(...this.selectedForeignPlayerList);
          return;
        } else {
          this.selectedForeignPlayerList.push(player);
        }
        this.selectedPlayerList.push(...this.selectedForeignPlayerList);
      } else if (this.step == 1) {
        if (this.selectedLocalPlayerList.length >= this.maxLocalPlayer) {
          this.showErrorDialog("加入失敗", "本土球員已經超過上限");
          this.selectedPlayerList.push(...this.selectedLocalPlayerList);
          return;
        }
        if (this.selectedPoints + player.current_value > this.totalPoints) {
          this.showErrorDialog("加入失敗", "球隊價值超過上限");
          this.selectedPlayerList.push(...this.selectedLocalPlayerList);
          return;
        }
        this.selectedPoints = this.selectedPoints + player.current_value;
        this.selectedLocalPlayerList.push(player);
        this.selectedPlayerList.push(...this.selectedLocalPlayerList);
      }

      this.getSelectedPlayersPage(this.selectedPlayerPage);
      this.getPlayersPage(this.playerPage);
    },
    getSelectedPlayersPage(page) {
      this.selectedPlayerPage = page;

      this.currentSelectedPlayerList = [];
      let number = 10 * this.selectedPlayerPage;
      this.currentSelectedPlayerList = this.selectedPlayerList.slice(
        number - 10,
        number
      );
    },
    onSelectedPlayItemClick(player) {
      this.selectedPlayerList = [];

      if (this.step == 2) {
        let tempForeignList = Object.assign([], this.selectedForeignPlayerList);
        this.selectedForeignPlayerList = [];
        for (let index in tempForeignList) {
          let temp = tempForeignList[index];
          if (temp.id != player.id) {
            this.selectedForeignPlayerList.push(temp);
          }
        }
        this.selectedPlayerList.push(...this.selectedForeignPlayerList);
      } else if (this.step == 1) {
        this.selectedPoints = this.selectedPoints - player.current_value;
        let tempLocalList = Object.assign([], this.selectedLocalPlayerList);
        this.selectedLocalPlayerList = [];
        for (let index in tempLocalList) {
          let temp = tempLocalList[index];
          if (temp.id != player.id) {
            this.selectedLocalPlayerList.push(temp);
          }
        }
        this.selectedPlayerList.push(...this.selectedLocalPlayerList);
      }

      this.getSelectedPlayersPage(this.selectedPlayerPage);
      this.getPlayersPage(this.playerPage);
    },
    onSelectedPlayerLeftArrowClick() {
      if (this.selectedPlayerPage - 1 > 0) {
        this.getSelectedPlayersPage(this.selectedPlayerPage - 1);
      }
    },
    onSelectedPlayerRightArrowClick() {
      if (
        this.selectedPlayerPage + 1 <=
        Math.ceil(this.selectedPlayerList.length / 10)
      ) {
        this.getSelectedPlayersPage(this.selectedPlayerPage + 1);
      }
    },
    filterChange() {
      this.sortValue.type = "";
      this.sortValue.order = "";
      let tempCountry;
      let tempPosition;
      let tempNameText;
      if (this.step == 1) {
        tempCountry = "LOCAL";
      } else {
        tempCountry = "FOREIGN";
      }
      if (this.position == "ALL") {
        tempPosition = null;
      } else {
        tempPosition = this.position;
      }
      if (validStringEmpty(this.nameText)) {
        tempNameText = null;
      } else {
        tempNameText = this.nameText;
      }
      this.getSeasonDraftData(tempCountry, tempPosition, tempNameText);
    },
    preStep() {
      this.step = 1;
    },
    nextStep() {
      if (this.selectedLocalPlayerList.length != 9) {
        this.showErrorDialog("失敗", "必須選滿9名本土球員");
        return;
      }

      this.step = 2;
    },
    toMyteam() {
      this.$router.push({ name: "MyTeam" });
    },
    showErrorDialog(title, text) {
      this.dialogErrorData.title = title;
      this.dialogErrorData.text = text;
      this.dialogErrorShow = true;
    },
    closeErrorDialog() {
      this.dialogErrorData.title = "";
      this.dialogErrorData.text = "";
      this.dialogErrorShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
    getTableSort(type) {
      if (type == this.sortValue.type) {
        if ("ASC" == this.sortValue.order) {
          return ["fas", "caret-up"];
        } else {
          return ["fas", "caret-down"];
        }
      } else {
        return ["fas", "sort"];
      }
    },
    sortClick(type) {
      if (type == this.sortValue.type) {
        if (this.sortValue.order == "DESC") {
          this.sortValue.order = "ASC";
        } else if (this.sortValue.order == "ASC") {
          this.sortValue.type = "";
          this.sortValue.order = "";
        }
      } else {
        this.sortValue.type = type;
        this.sortValue.order = "DESC";
      }
      this.getPlayersPage(this.playerPage);
    },
    sortData(allList, sortKey, sortData, order) {
      let tempList = Object.assign([], allList);
      if ("INFO" == sortData) {
        if ("ASC" == order) {
          return tempList.sort(function (a, b) {
            return a[sortKey] - b[sortKey];
          });
        } else if ("DESC" == order) {
          return tempList.sort(function (a, b) {
            return b[sortKey] - a[sortKey];
          });
        }
      } else if ("BOX" == sortData) {
        if ("ASC" == order) {
          return tempList.sort(function (a, b) {
            return a.box[sortKey] - b.box[sortKey];
          });
        } else if ("DESC" == order) {
          return tempList.sort(function (a, b) {
            return b.box[sortKey] - a.box[sortKey];
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/fantasy/create_team";

.bottom_actions {
  input,
  select {
    height: auto;
    padding: 6px 10px;
  }
}
</style>
